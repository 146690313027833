$input_height: 50px;
$input_back_color: $bg-color-body;
$input_text_color: $text-color;
$input_text_size: $font-size-20;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: $font-size-18;

$input_label_color: $text-muted;
$input_label_size: $font-size-18;

$input_border_color: $color-primary;
$input_border_radius: $round-corner-radius; //based on variable
$input_border_size: 1px;
$input_border_style: solid;

$label_checkbox_border_color: $text-muted;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: $color-error;
$message_errors_border_color: $color-error;
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: $color-success;
$message_success_border_color: lighten($color-success, 20%);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: $bg-color-body;

form {
    input.form-control, textarea.form-control {
        height: 100px;
        color: $input_text_color;
        font-size: $input_text_size;
        background-color: $color-white;
        border: 1px solid $color-2;
        border-radius: none;
    }
    input::placeholder, textarea::placeholder {
        font-weight: 500 !important;
        font-size: $font-size-18 !important;
        color: #666F88 !important;
        text-transform: uppercase !important;
        padding-left: 15px !important;
    }
    label {
        font-weight: 500;
        font-size: $font-size-18;
        color: #666F88;
        text-transform: uppercase;
    }
    .title {
        text-transform: uppercase;
        color: $input_text_color;
        font-weight: bold;
        margin-top: 1em;
    }
    .center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .form-group {
        margin-bottom: -15px;
    }
    .clear-both {
        clear: both;
    }

    .section-date-img {
        .left-side {
            width: 33%;
            margin-right: 65px;
        }
        .right-side {
            .section-image {
                .left {
                    width: 71%;
                    margin-right: 15px;
                }
                .right {
                    width: 100%;
                    .image {
                        width: 47%;
                        margin-bottom: 13px;
                        margin-right: 15px;
                    }
                }
            }
        }
        @media screen and (max-width: 1460px) {
            flex-direction: column;
            .left-side {
                width: 100%;
                margin: 0 0 50px 0;
                .btn-submit {
                    min-width: 330px !important;
                }
            }
            @media screen and (max-width: 1100px) {
                .right-side {
                    .section-image {
                        .right {
                            .image {
                                width: 46%;
                            }
                        }
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    .right-side {
                        .section-image {
                            .right {
                                .image {
                                    width: 45%;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .left-side {
                            .btn-submit {
                                min-width: 100% !important;
                            }
                        }
                        .right-side {
                            .section-image {
                                flex-direction: column;
                                .left {
                                    width: 100%;
                                    margin: 0 0 15px 0;
                                }
                                .right .image {
                                    width: 100%;
                                    margin: 0 0 15px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .list-checkBox {
            flex-wrap: wrap;
        }
        @media screen and (max-width: $size-xs-max) {
            .col-xs-12.col-sm-6.pl0, .col-xs-12.col-sm-6.pr0 {
                padding: 0;
            }
        }
    }

    label.item {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-transform: inherit;
        transition: 0.3s;

        font-weight: bold;
        font-size: $font-size-30;
        color: $color-black;
        text-transform: uppercase;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        @media screen and (max-width: 1600px) {
            font-size: 1.25rem;
            @media screen and (max-width: 1420px) {
                font-size: 1.1rem;
                @media screen and (max-width: 1200px) {
                    font-size: 1.2rem;
                    width: 28%;
                    padding: 0 0 25px 35px;
                    @media screen and (max-width: $size-sm-max) {
                        width: 43.3%;
                        @media screen and (max-width: $size-xs-max) {
                            width: 100%;
                            .checkmark {
                                top: -3px;
                            }
                        }
                    }
                }
            }
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: transparent;
        border: 2px solid $color-2;
        border-radius: 15px;
        transition: 0.3s;
    }

    /* On mouse-over, add a grey background color */
    .checkmark:hover input ~ .checkmark {
        background-color: #b8031b;
        transition: 0.3s;
    }

    /* When the checkbox is checked, add a blue background */
    .item input:checked ~ .checkmark {
        background-color: $color-1;
        border: 2px solid $color-1;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .item input:checked ~ .checkmark:after {
        display: none;
    }
    .item .checkmark:after {
        left: 10px;
        top: 6px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
           
            


//// checkboxes and radiobuttons style ///////


    .checkbox , .radio {
        display: flex;
        cursor: pointer;
        width: 100%;
        & > span {
            color: #024b94;
            padding: 0.5rem 0.25rem;
            margin-right: auto;
        }
        & >  input {
          height: 25px;
          width: 25px;
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          appearance: none;
          outline: none;
          transition-duration: 0.3s;
          background-color: $input_back_color;
          cursor: pointer;
          position: inherit!important;
        }
        & > input:checked {
          border: 3px solid $input_back_color;
          background-color: #024b94;
        }
    }

    .radio > input {
      border-radius: 50%;
    }

//// End checkboxes and radiobuttons style///////

    textarea {
        //margin: 2em 0;
        height: $textarea_height !important;
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 30px 0 0 0;
    }

}

.has-error {
    .form-control {
        color: $message_errors_text_color;
    }
}
.help-block {
    min-height: 15px;
}
.with-errors {
    li {
        line-height: 1;
    }
}



.messages {
    padding: 30px;
    margin-bottom: 2em;
    &.errors, &.success {
        p {
            font-size: $font-size-20;
            line-height: 1;
            margin: 0
        }
    }
    &.errors {
        background-color: $message_errors_background_color;
        border-radius: $message_errors_border_radius;
        border: $message_errors_border_size solid $message_errors_border_color;
        color: $message_errors_text_color;
        p {
            color: $message_errors_text_color;
        }
    }
    &.success {
        background-color: $message_success_background_color;
        border-radius: $message_success_border_radius;
        border: $message_success_border_size solid $message_success_border_color;
        color: $message_success_text_color;
        p {
            color: $message_success_text_color;
        }
    }
}
