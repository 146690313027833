.slideshow-wrap {
    position: relative;

    .img-quote {
        position: absolute;
        top: 14%;
        left: 3%;
        z-index: 10;
        @media screen and (max-width: $size-sm-max) {
           width: 30%;
            @media screen and (max-width: $size-xs-max) {
               display: none;
            }
        }
    }

    .caption {
        position: absolute;
        top: 25%;
        left: 56%;
        width: 100%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        h2 {
          font-weight: bold;
          font-size: $font-size-80;
          color: $color-1;
          text-transform: uppercase;
          padding-bottom: 15px;
        }
        h4 {
          font-weight: 600;
          font-size: $font-size-20;
          color: $color-black;
        }
        a.btn-plus {
            width: 230px;
            height: 70px;
            margin-top: 25px;
            border-radius: 20px;
            h5 {
              font-weight: 500;
              font-size: $font-size-20;
              color: #DEEBEE;
              text-transform: uppercase;
            }
            &:hover {
                img {
                    transform: rotate(90deg);
                    transition: 0.5s;
                }
            }
        }
        @media screen and (max-width: 1830px) {
            h2 {
              font-size: 3.8rem;
            }
            @media screen and (max-width: 1770px) {
                h2 {
                  font-size: 3.5rem;
                }
                @media screen and (max-width: 1610px) {
                    h2 {
                      font-size: 3.25rem;
                    }
                    @media screen and (max-width: 1510px) {
                        h2 {
                          font-size: 3rem;
                        }
                        @media screen and (max-width: 1380px) {
                            h2 {
                              font-size: 2.75rem;
                            }
                            @media screen and (max-width: 1150px) {
                                h2 {
                                  font-size: 2.5rem;
                                }
                                @media screen and (max-width: 1024px) {
                                    h2 {
                                      font-size: 2.2rem;
                                    }
                                    @media screen and (max-width: $size-sm-max) {
                                        h2 {
                                          font-size: 2rem;
                                        }
                                        a.btn-plus {
                                          display: none;
                                        }
                                        @media screen and (max-width: $size-xs-max) {
                                          position: initial;
                                          transform: initial;
                                          background: url("../images/icon_quote.png") no-repeat;
                                          background-position: initial;
                                          padding: 75px 5% 50px 5%;
                                          h2 br {
                                            display: none;
                                          }
                                          a.btn-plus {
                                            display: flex;
                                          }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .fb {
        position: absolute;
        top: 10%;
        right: 5%;
        z-index: 10;
        @media screen and (max-width: $size-xs-max) {
          top: 5%;
        }
    }

    .slideshow {
      	width: 100%;
      	overflow: hidden;
      	position: relative;
      	.placeholder {
        		width: 100vw;
        		height: auto;
        		display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                height: calc(100vw / 480 * 600);
            }
      	}
        &.mobile {
            display: none;
        }
      	.slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }
        		img {
        			 width: 100vw!important;
        			 height: auto;
        			 display: block;
        			 position: relative;
        		}
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
        		}
        		&.active {
          			right: 0;
                left: 0;
          			z-index: 10;
                opacity: 1;
                transition: 1.5s;
        		}
        	}
        	@media screen and (max-width: 480px) {
              &.mobile {
                  display: block;
              }
              &.ecran {
                  display: none;
              }
        	}
      }
      @media screen and (max-width: $size-slider-breakpoint) {
          .caption {
              top: $size-nav-height-mobile + 20px;
          }
      }
}
