// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';


a.btn-plus {
    background: $color-1;
    width: 340px;
    height: 50px;
    .icon {
        img {
            transition: 0.5s;
        }
    }
    h5 {
        font-family: $font-family-2;
        font-weight: 600;
        font-size: $font-size-14;
        color: #F0EFE7;
        text-transform: uppercase;
        line-height: 1.5;
    }
    &:hover {
        img {
            transform: rotate(180deg);
            transition: 0.5s;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        h5 {
            font-size: 15px;
        }
    }
}


/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-text {
    background: #E9CD9E33;
    padding: 100px 5%;
    text-align: center;
    h2 {
        font-weight: normal;
        font-size: $font-size-50;
        color: $color-black;
        text-transform: uppercase;
        padding-bottom: 15px;
    }
    @media screen and (max-width: 790px) {
        p br {
            display: none;
        }

    }
}

#section-images {
    .center-side {
        position: relative;
        a.btn-plus {
            width: 355px;
            height: 70px;
            position: absolute;
            bottom: 5%;
        }
    }
    @media screen and (max-width: $size-md-max) {
        .center-side {
            a.btn-plus {
                width: 315px;
            }
        }
        @media screen and (max-width: $size-xs-max) {
            flex-direction: column;
            .center-side {
                margin: 0.75rem 0;
                a.btn-plus {
                    width: 340px;
                }
            }
        }
    }
}

#section-carousel-text {
    padding: 50px 5%;
    margin: 50px 0 25px 0;
    position: relative;
    .main-content {
        width: 55%;
        margin-right: 20px;
        position: relative;
        .item {
            .col-xs-12.txt {
                padding-left: 0;
                padding-right: 0; 
            }
        }
        .owl-nav {
            position: absolute;
            right: 5%;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            .owl-prev {
                padding-right: 35px;
            }
        }           
    }
    .text-side {
        width: 45%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-black;
            line-height: 1.5;
            padding-bottom: 15px;
        }
        p {
            margin-bottom: 35px;
        }
    }
    &::before {
        content: '';
        background: $color-1;
        z-index: -1;
        width: 43%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        margin-right: 5%;
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        .main-content {
            width: 80%;
            margin: 0 0 35px 0;
        }
        .text-side {
            width: 100%;
        }
        &::before {
            width: 100%;
            height: 50%;
        }
        @media screen and (max-width: $size-sm-max) {
            .main-content {
                width: 100%;
            }
            &::before {
                height: 45%;
            }
            @media screen and (max-width: $size-xs-max) {
                &::before {
                    height: 40%;
                }
                @media screen and (max-width: 360px) {
                    .text-side {
                        a.btn-plus {
                            width: 100%;
                        }
                    }
                    &::before {
                        height: 35%;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-title {
    padding: 50px 5%;
    text-align: center;
    h3 {
        font-weight: 500;
        font-size: $font-size-30;
        color: $color-2;
        line-height: 1.3;
    }
    @media screen and (max-width: 1540px) {
        h3 br {
            display: none;
        }
        @media screen and (max-width: 375px) {
            h3 {
                font-size: 1.3rem;
            }
        }
    }
}

#section-images-text {
    background: #E9CD9E33;
    padding: 100px 5%;
    .image-side {
        width: 60%;
        margin-right: 25px;
    }
    .text-side {
        width: 40%;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-1;
            line-height: 1.1;
        }
        p span {
            font-weight: bold;
        }
    }
    @media screen and (max-width: 1610px) {
        .text-side {
            h3 {
                font-size: 1.8rem;
            }
        }
        @media screen and (max-width: 1550px) {
            .text-side {
                h3 {
                    font-size: 1.6rem;
                }
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column;
                .image-side {
                    width: 100%;
                    margin: 0 0 35px 0;
                }
                .text-side {
                    width: 100%;
                    h3 br {
                        display: none;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    padding: 75px 5%;
                    .image-side {
                        flex-direction: column;
                        .image.mr3 {
                            margin: 0 0 0.75rem 0;
                        }
                    }
                    @media screen and (max-width: 375px) {
                        .text-side {
                            a.btn-plus {
                                width: 300px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-image {
    padding: 50px 0;
    .left-side {
        background: $color-2;
        width: 43%;
        margin-right: 15px;
        padding: 0 5%;
        h3, p {
            color: #DEEBEE;
        }
        p {
            font-size: $font-size-20;
        }
        .bloc .text {
            width: 100%;
        }
    }
    .right-side {
        width: 57%;
        background: url("../images/services_section02_img01.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    @media screen and (max-width: 1685px) {
        .left-side {
            padding: 50px 5%;
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            .left-side {
                padding: 75px 5%;
                width: 100%;
            }
            .right-side {
                width: 65%;
                margin: 50px auto 0 auto;
            }
            @media screen and (max-width: $size-sm-max) {
                .right-side {
                    width: 100%;
                    margin: 0 auto;
                }
                @media screen and (max-width: $size-xs-max) {
                    .right-side {
                        background: url("../images/services_section02_img01_m.jpg") no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
    }
}

#section-animaux-text {
    margin-bottom: 50px;
    .left-side {
        width: 62%;
        padding: 100px 25px 100px 5%;
        background-image: linear-gradient(to right, #C9830F 0%, #C9830F 87%, #E9CD9EBA 87%, #E9CD9EBA 100%);
        .image {
            .up {
                margin-bottom: 19px;
            }
        }
    }
    .right-side {
        background: #E9CD9EBA;
        width: 55%;
        h3 {
            font-weight: bold;
            font-size: $font-size-60;
            color: $color-1;
            padding-bottom: 20px;
        }
        p {
            span {
                font-weight: bold;
            }
        }
        a.btn-plus {
            width: 295px;
            margin-top: 75px;
        }
    }
    @media screen and (max-width: 1600px) {
        .right-side h3 {
            font-size: 2.7rem;
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            .left-side {
                background: $color-1;
                width: 100%;
                padding: 75px 5%;
                justify-content: center;
            }
            .right-side {
                width: 100%;
                padding: 75px 5%;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side {
                    flex-direction: column;
                    .image.mr5 {
                        margin: 0 0 1.25rem 0;
                    }
                    .image.mr4 {
                        margin: 0 0 1rem 0;
                    }
                    .up {
                        margin: 0 0 1rem 0 !important;
                    }
                }
                .right-side {
                    h3 {
                        font-size: 2.1rem;
                        br {
                            display: none;
                        }
                    }
                    a.btn-plus {
                        width: 265px;
                    }
                }
            }
        }
    }
}

#section-text-animaux {
    margin-bottom: 50px;
    .left-side {
        background: #DEEBEE;
        width: 55%;
        padding-left: 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-60;
            color: $color-2;
            padding-bottom: 20px;
        }
        p {
            span {
                font-weight: bold;
            }
        }
        a.btn-plus {
            background: $color-2;
            width: 295px;
            margin-top: 75px;
        }
    }
    .right-side {
        width: 68%;
        padding: 100px 5% 100px 25px;
        background-image: linear-gradient(to left, #74ABBA 0%, #74ABBA 87%, #DEEBEE 87%, #DEEBEE 100%);
    }
    @media screen and (max-width: 1600px) {
        .left-side h3 {
            font-size: 2.7rem;
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column-reverse;
            .right-side {
                background: $color-2;
                width: 100%;
                padding: 75px 5%;
                justify-content: center;
            }
            .left-side {
                width: 100%;
                padding: 75px 5%;
            }
            @media screen and (max-width: $size-xs-max) {
                .right-side {
                    flex-direction: column;
                    .image.mr5 {
                        margin: 0 0 1.25rem 0;
                    }
                }
                .left-side {
                    h3 {
                        font-size: 2.1rem;
                        br {
                            display: none;
                        }
                    }
                    a.btn-plus {
                        width: 265px;
                    }
                }
            }
        }
    }
}


/*******************************************************************************
* RÉSERVATION
*******************************************************************************/

#section-blue {
    padding: 50px 10%;
    background: $color-2;
    margin-bottom: 15px;
    .left-side {
        width: 55%;
        border-right: 3px solid #DEEBEE;
        margin-right: 75px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #DEEBEE;
            line-height: 1.5;
        }
    }
    .right-side {
        p {
            font-size: $font-size-20;
            color: #DEEBEE;
            span {
                font-weight: bold;
            }
        }
    }
    @media screen and (max-width: 1400px) {
        padding: 50px 5%;
        @media screen and (max-width: 1100px) {
            flex-direction: column;
            align-items: initial;
            .left-side {
                width: 100%;
                border-right: none;
                margin: 0 0 25px 0;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side {
                    h3 br {
                        display: none;
                    }
                }
            }
        }
    }
}

#section-yellow {
    padding: 60px 10%;
    background: #E9CD9E38;
    .left-side {
        width: 57%;
        h2 {
            font-weight: bold;
            font-size: $font-size-80;
            color: $color-1;
            text-transform: uppercase;
        }
    }
    .right-side {
        p {
            font-size: $font-size-20;
        }
    }
    @media screen and (max-width: 1400px) {
        padding: 60px 5%;
        @media screen and (max-width: 1100px) {
            flex-direction: column;
            align-items: initial;
            .left-side {
                width: 100%;
                margin-bottom: 25px;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side {
                    h2 {
                        font-size: 3rem;
                    }
                }
            }
        }
    }
}

#section-formulaire {
    padding: 25px 5%;
    h3 {
        font-weight: bold;
        font-size: $font-size-30;
        color: $color-black;
        padding-bottom: 50px;
    }
    .section-form {
        background: #DEEBEE;
        padding: 50px 6%;
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 25px 0;
        .section-form {
            padding: 50px 5%;
        }
        @media screen and (max-width: 375px) {
            h3 {
                font-size: 1.3rem;
                padding-bottom: 35px;
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 0 5% 50px 5%;
    background: #CACBD53C;
    .info-heures {
        position: relative;
        margin-top: -250px;
    }
    .section-info {
        width: 65%;
        margin-right: 15px;
        background: #DEEBEE;
        padding: 50px 100px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-2;
            padding-bottom: 15px;
        }
        p {
            font-size: $font-size-20;
        }
        .info {
            padding-top: 25px;
            h4 {
                font-weight: bold;
                font-size: $font-size-26;
                color: $color-2;
            }
            p {
                font-size: $font-size-18;
                font-weight: 500;
                color: $color-2;
            }
        }
    }
    .section-heures {
        width: 35%;
        padding: 50px 2% 35px 5%;
        background: $color-2;
        h3 {
            font-weight: bold;
            font-size: $font-size-26;
            color: #DEEBEE;
            text-transform: initial;
            padding-bottom: 15px;
        }
        .jours-heures {
            .jour {
                width: 50%;
            }
            h4, h5 {    
                font-weight: bold;
                font-size: $font-size-18;
                color: #DEEBEE;
                line-height: 1.8;
            }
            h5 {
                font-weight: normal;
            }
        }
        h4.text {
            font-weight: bold;
            font-size: $font-size-20;
            color: #DEEBEE;
            line-height: 1.4;
        }
    }
    @media screen and (max-width: 1750px) {
        .section-info {
            padding: 50px 75px;
        }
        @media screen and (max-width: 1550px) {
            .section-info {
                .info {
                    flex-wrap: wrap;
                    .section-tel {
                        width: 50%;
                        margin-bottom: 36px;
                    }
                    .section-courriel {
                        width: 50%;
                        margin: 0;
                    }
                }
            }
            @media screen and (max-width: 1150px) {
                .info-heures {
                    flex-direction: column;
                    margin-top: -200px;
                    .section-info {
                        padding: 75px 50px;
                        margin: 0 0 25px 0;
                        width: 100%;
                    }
                    .section-heures {
                        width: 100%;
                        padding: 75px 50px;
                        .jours-heures .jour {
                            width: 30%;
                        }
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    margin-top: -130px;
                    .info-heures {
                        .section-info {
                            padding: 55px 25px;
                            .info {
                                .section-tel {
                                    width: 100%;
                                    margin-bottom: 15px;
                                }
                                .section-courriel {
                                    width: 100%;
                                    margin: 0 0 15px 0;
                                }
                            }
                        }
                        .section-heures {
                            padding: 55px 25px;
                            .jours-heures .jour {
                                width: 45%;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-map {
    padding: 25px 5%;
    @media screen and (max-width: $size-sm-max) {
        #map-canvas {
            height: 500px !important;
        }
    }
}

div#content {
    h3#firstHeading {
        font-size: $font-size-28;
        padding-bottom: 10px;
        color: $color-1;
    }
    p a {
        color: $color-black;
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   

}
