.btn {
    background-color: $color-1;
    border: none;
    border-radius: 20px;
    padding: 20px;
    font-weight: 500;
    font-size: $font-size-20;
    color: #DEEBEE;
    text-transform: uppercase;
    width: 100%;
    transition: all 0.2s ease-in;
    img {
        transition: 0.5s;
    }

    &:hover {
        color: #DEEBEE;
        img {
            transform: rotate(90deg);
            transition: 0.5s;
        }
    }
}
